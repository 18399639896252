import { useCallback } from 'react';

import urlParser from 'bloko/common/urlParser';

import {
    HiringManagerNotificationType,
    useHiringManagerNotification,
} from 'src/components/HiringManager/hooks/useHiringManagerNotification';
import { VacanciesInfo } from 'src/models/candidatesList';

export const useCopyClipboard = (
    resumeHash: string,
    vacancyInfo?: VacanciesInfo,
    topicId?: string[]
): (() => Promise<void>) => {
    const showNotification = useHiringManagerNotification();
    return useCallback(async () => {
        const vacancyName = [vacancyInfo?.name].filter((x): x is string => !!x);
        try {
            const url = urlParser(window.location.href);
            url.pathname = `/resume/${resumeHash}`;
            url.params = {};
            if (vacancyInfo?.vacancyId) {
                url.params.vacancyId = vacancyInfo.vacancyId;
            }
            if (topicId) {
                url.params.t = topicId;
            }
            await navigator.clipboard.writeText(url.href);
            showNotification?.(vacancyName, HiringManagerNotificationType.ResumeLinkCopied);
        } catch (_) {
            showNotification?.(vacancyName, HiringManagerNotificationType.ResumeLinkCopyFailed);
        }
    }, [resumeHash, vacancyInfo, topicId, showNotification]);
};
